@tailwind base;
@tailwind components;
@tailwind utilities;

input, textarea {
  padding: 0 5px;
}

input:focus {
  outline: none; /* Убираем стандартную синюю обводку */
  ring: 0; /* Убираем ring */
  box-shadow: none; /* Убираем тень */
}

.loader__block {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.loader__block img {
  width: 40px;
  height: 40px;
}

/* SwiperCheckbox styles */
.swiper-checkbox {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.swiper-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #e18a44;
}

input:checked + .slider:before {
  transform: translateX(26px);
}


.doc__send {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #e18a44;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 10px;
}

.doc__send img {
  width: 30px;
  height: 30px;
}